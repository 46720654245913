import React from 'react';
import PropTypes from 'prop-types';

import TeiMultiStepForm from '../../multi-step-form';

import './style.scss';

const Form = ({ meta, teiCalculatorSettings }) => {
	return (
		<section className="tei__form-section">
			<div className="row align-center">
				<div className="column small-12 medium-11">
					<h2 className="form__section-title">{meta.title}</h2>
				</div>
				<div className="column small-12 medium-10 large-6">
					<TeiMultiStepForm {...meta} teiCalculatorSettings={teiCalculatorSettings} />
				</div>
			</div>
		</section>
	)
}

Form.propTypes = {
	meta: PropTypes.object.isRequired,
}

export default Form;
