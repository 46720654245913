import React from 'react';
import Image from '../../image';
import { sanitize } from '../../../utils/functions';
import './style.scss';


const getRandomInt = max => {
	return Math.floor(Math.random() * Math.floor(max));
}

const Testimonial = ({ data }) => {

	const loading = false;
	const error = false;


	if (loading) {
		return (
			<h3 className="text-center-left">Loading testimonials...</h3>
		);
	};

	if (error || !data) {
		return null;
	};

	if (data) {
		const { testimonials } = data;
		const desiredTestimonialIndex = getRandomInt(testimonials.length);
		const testimonial = testimonials[desiredTestimonialIndex];
		return (
			<TestimonialContents testimonial={testimonial} />
		);
	};

	return null;

};

const TestimonialContents = ({ testimonial }) => {
	return (
		<section className="tei__testimonial-section">
			<div className="row align-center">
				<div className="column medium-12 large-8">
					<div className="tei-testimonials__list">
						<div className="tei-testimonials__header">
							<figure className="tei-testimonials__logo">
								<Image {...testimonial.logo} lazy={false} className="tei-testimonials__logo-image" />
							</figure>
							<div className="tei-testimonials__content" dangerouslySetInnerHTML={{ __html: sanitize(testimonial.content) }} />
						</div>
						<div className="tei-testimonials__meta">
							<figure className="tei-testimonials__author">
								<Image {...testimonial.authorProfilePicture} lazy={false} className="tei-testimonials__author-image" />
							</figure>
							<div className="tei-testimonials__details">
								<h4>{testimonial.authorName}</h4>
								<p>{testimonial.authorDesignation}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default React.memo(Testimonial);
