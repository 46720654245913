import React from 'react';
import PropTypes from 'prop-types';

import { sanitize } from '../../../utils/functions';

import './style.scss';

const Hero = ( { title, excerpt } ) => {
	return (
		<section className="tei__hero-section">
			<div className="row">
				<div className="column medium-12 large-10">
					<h1 className="hero__title">{ title }</h1>
				</div>
				<div className="column small-12 medium-11 large-8">
					<div className="hero__excerpt" dangerouslySetInnerHTML={ { __html: sanitize( excerpt ) } }></div>
				</div>
			</div>
		</section>
	)
}

Hero.propTypes = {
	title: PropTypes.string.isRequired,
	excerpt: PropTypes.string
}

Hero.defaultProps = {
	excerpt: ''
}

export default Hero;
