/**
 * External dependencies.
 */
import React from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { navigate } from "gatsby"

/**
 * Internal dependencies.
 */
import { sendContactClickEvent } from '../../utils/functions';


let formulaData = null;

const MultiStepForm = ({ teiFormStep1, teiFormStep2, teiFormFinalStep, teiCalculatorSettings }) => {

	const data = teiCalculatorSettings;

	if (data) {
		formulaData = teiCalculatorSettings.TeiOptions.teiVerticals;
	}

	const getFieldValue = (formData, search) => {
		const fieldValue = formData.find(item => item.name === search);
		return fieldValue ? fieldValue.value : null;
	}

	const getSelecetedVertical = (key) => {
		return formulaData.find(item => item.verticalType === key);
	}


	const generateYearlyRevenue = (formData) => {
		const selectedVertical = getFieldValue(formData, 'tei_vertical');
		const selectedFormula = getSelecetedVertical(selectedVertical);
		const monthlyVisitors = getFieldValue(formData, 'tei_monthly_visitors');
		const publisherName = getFieldValue(formData, 'website');
		const totalMonthlyVisitors = monthlyVisitors ? Number(monthlyVisitors) : 0;

		const monthlyPageViews = getFieldValue(formData, 'total_monthly_editorial_page_views');
		const totalMonthlyPageViews = monthlyPageViews ? Number(monthlyPageViews) : 0;

		const averagePageViewsPerSession = getFieldValue(formData, 'average_page_views_per_session');
		const totalPageViewPerSession = averagePageViewsPerSession ? Number(averagePageViewsPerSession) : 0;

		const averageSessionDurationMinute = getFieldValue(formData, 'average_session_duration');
		const averageSessionDurationSecond = getFieldValue(formData, 'seconds');

		const totalSessionDuration = averageSessionDurationMinute && averageSessionDurationSecond ? (Number(averageSessionDurationMinute) * 60) + Number(averageSessionDurationSecond) : 0;

		const rpm = 0;

		const calculatedMonthlyPvs = totalMonthlyPageViews * (selectedFormula.pageviewByActiveUsers / 100) * (selectedFormula.improvementInPageview / 100) / 1000;
		const calculatedRpm = rpm && 0 < Number(rpm) ? Number(rpm) + selectedFormula.rpm : (selectedFormula.rpm * 10) + selectedFormula.rpm;

		// Lift in pageviews
		const liftInPageviews = (totalPageViewPerSession * (selectedFormula.improvementInPageview / 100)) + totalPageViewPerSession;

		// Pvs with Openweb
		const pvsWithOpenweb = totalMonthlyPageViews + (totalMonthlyPageViews * (selectedFormula.pageviewByActiveUsers / 100) * (selectedFormula.improvementInPageview / 100));

		const incrementalRpm = pvsWithOpenweb / 1000 * selectedFormula.rpm * 12;

		// yearly revenue
		const yearlyRevenue = (calculatedMonthlyPvs * calculatedRpm * 12) + incrementalRpm;

		// Lift in session duration
		const liftInSessionDuration = (totalSessionDuration * (selectedFormula.improvementInTimePerDay / 100)) + totalSessionDuration;

		// Session time with Openweb
		const sessionTimeWithOpenWeb = totalSessionDuration * (1 + (selectedFormula.improvementInTimePerDay / 100));

		const generatedValues = {
			publisherName,
			yearlyRevenue,
			liftInPageviews,
			pvsWithOpenweb,
			liftInSessionDuration,
			sessionTimeWithOpenWeb,
			averagePageViewsPerSession,
			totalMonthlyVisitors,
			totalMonthlyPageViews,
			totalSessionDuration,
			selectedFormula
		}

		return generatedValues;
	}


	const formateValidationMessage = (event) => {

		const errLabels = event.target.parentElement.parentElement.querySelectorAll('.hs-error-msg');

		errLabels.forEach(label => {

			if (!label) {
				return;
			};

			const labelText = label.textContent;

			if (1000000 <= event.target.value) {
				label.parentElement.parentElement.style.display = 'none';
			} else {
				const replaceLabel = labelText.replace('1000000.', '1,000,000');
				label.textContent = replaceLabel;
				label.parentElement.parentElement.style.display = 'block';
			}

		});
	};


	useHubspotForm({
		portalId: teiFormStep1.portalId,
		formId: teiFormStep1.formId,
		target: '#tei_step_1',
		onFormReady: step1Form => {
			const formSectionWrapper = document.querySelector('.tei_multistep_form_wrap');
			const step1FormWrapper = document.querySelector('#tei_step_1');
			const step2FormWrapper = document.querySelector('#tei_step_2');
			const finalStepFormWrapper = document.querySelector('#tei_step_final');
			const step1HsForm = step1FormWrapper.querySelector('.hs-form');
			const submitButton = step1HsForm.querySelector('input[type="submit"]');
			const step1FormHeight = step1FormWrapper.clientHeight;
			formSectionWrapper.style.height = step1FormHeight + 'px';
			const monthlyPageViewField = step1HsForm.querySelector('input[name="total_monthly_editorial_page_views"]');
			monthlyPageViewField.min = 1000000;
			monthlyPageViewField.addEventListener('keyup', (event) => {
				formateValidationMessage(event);
			});

			submitButton.addEventListener('click', (e) => {
				if (step1HsForm.checkValidity()) {
					e.preventDefault();
					sendContactClickEvent('Next Button', 'Next1');
					window.hbspt.forms.create(
						{
							portalId: teiFormStep2.portalId,
							formId: teiFormStep2.formId,
							target: '#tei_step_2',
							onFormReady: function (step2Form) {
								step1FormWrapper.classList.remove('show');
								step2FormWrapper.classList.add('show');
								const step2HsForm = document.querySelector('#tei_step_2 .hs-form');
								const submitButton = step2HsForm.querySelector('input[type="submit"]');
								const step2FormHeight = step2FormWrapper.clientHeight;
								formSectionWrapper.style.height = step2FormHeight + 'px';

								const step2SubmitWrapper = document.querySelector('#tei_step_2 .hs_submit');
								const step1BackButton = document.createElement('button');
								step1BackButton.type = 'button';
								step1BackButton.className = 'tei__back-button';
								step1BackButton.textContent = 'Back';
								step1BackButton.addEventListener('click', (e) => {
									e.preventDefault();
									step2FormWrapper.classList.remove('show');
									step1FormWrapper.classList.add('show');
								});
								step2SubmitWrapper.prepend(step1BackButton);

								const monthlyVisitorsField = step2HsForm.querySelector('input[name="tei_monthly_visitors"]');
								const average_page_views_per_session = step2HsForm.querySelector('input[name="average_page_views_per_session"]');

								// monthlyVisitorsField.min = 1000000;

								const averagePageViewPerSessionField = step2HsForm.querySelector('input[name="average_page_views_per_session"]');
								// averagePageViewPerSessionField.min = 0.1;

								// monthlyVisitorsField.addEventListener( 'keyup', ( event ) => {
								// 	formateValidationMessage( event );
								// } );

								const sessionDurationFields = step2HsForm.querySelectorAll('.hs-fieldtype-select');

								sessionDurationFields.forEach((sessionField) => {

									const field = sessionField.querySelector('option');
									if (field) {
										const fieldText = field.textContent;
										field.textContent = fieldText.replace('*', '');
									}
								});

								const minutesField = step2HsForm.querySelector('select[name="average_session_duration"]');
								const secondsField = step2HsForm.querySelector('select[name="seconds"]');
								minutesField.addEventListener('change', (e) => {
									const wrapElement = e.target.parentElement;
									wrapElement.classList.add('is-selected');
									let label = "Mins";
									const targettedSecondOption = secondsField.querySelector('option[value="0"]');
									const selectedMinute = e.target.value;

									if ('0' === selectedMinute) {
										targettedSecondOption.disabled = true;
									} else {
										targettedSecondOption.disabled = false;
									}

									if (1 >= Number(selectedMinute)) {
										label = "Min";
									}

									wrapElement.setAttribute('data-label', label);

								});

								secondsField.addEventListener('change', (e) => {
									const wrapElement = e.target.parentElement;
									wrapElement.classList.add('is-selected');
									let label = "Secs";
									const targettedMinuteOption = minutesField.querySelector('option[value="0"]');
									const selectedSecond = e.target.value;

									if ('0' === selectedSecond) {
										targettedMinuteOption.disabled = true;
									} else {
										targettedMinuteOption.disabled = false;
									}

									if (1 >= Number(selectedSecond)) {
										label = "Sec";
									}

									wrapElement.setAttribute('data-label', label);
								});


								submitButton.addEventListener('click', (e) => {

									// debugger;
									if (step2HsForm.checkValidity()) {
										e.preventDefault();
										sendContactClickEvent('Next Button', 'Next2');
										finalStepFormWrapper.replaceChildren('');
										let genaratedValues = null;

										window.hbspt.forms.create(
											{
												portalId: teiFormFinalStep.portalId,
												formId: teiFormFinalStep.formId,
												target: '#tei_step_final',
												onFormReady: () => {
													step2FormWrapper.classList.remove('show');
													finalStepFormWrapper.classList.add('show');
													const finalStepFormHeight = finalStepFormWrapper.clientHeight;
													formSectionWrapper.style.height = finalStepFormHeight + 'px';

													const teiFinalSubmitWrapper = document.querySelector('#tei_step_final .hs_submit');
													const step2BackButton = document.createElement('button');
													step2BackButton.type = 'button';
													step2BackButton.textContent = 'Back';
													step2BackButton.className = 'tei__back-button';
													step2BackButton.addEventListener('click', (e) => {
														e.preventDefault();
														finalStepFormWrapper.classList.remove('show');
														step2FormWrapper.classList.add('show');
													});
													teiFinalSubmitWrapper.prepend(step2BackButton);

													const step1FormData = step1Form.serializeArray();
													const step2FormData = step2Form.serializeArray();
													const preservedFormData = [...step1FormData, ...step2FormData];

													preservedFormData.map(item => {
														const inputField = document.querySelector(`.tei_step_final input[name="${item.name}"]`);
														if (inputField) {
															inputField.value = item.value;
														}
														return true;
													});

												},

												onFormSubmit: form => {
													const allFormData = form.serializeArray();
													genaratedValues = generateYearlyRevenue(allFormData);
													sendContactClickEvent('Show me the Impact', 'Submit3');
												},

												onFormSubmitted: () => {
													navigate(
														"/impact-calculator/result",
														{
															state: { genaratedValues },
														}
													)
												}
											}
										);
									} else {
									}

								});
							}

						}
					);
				}

			})

		}
	});




	return (
		<div className="tei_multistep_form_wrap">
			<div id="tei_step_1" className="tei_step_1 show"></div>
			<div id="tei_step_2" className="tei_step_2"></div>
			<div id="tei_step_final" className="tei_step_final"></div>
		</div>
	)

};

export default MultiStepForm;
