/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import TeiHero from '../../components/tei-calculator/hero'
import TeiForm from '../../components/tei-calculator/form'
import TeiTestimonial from '../../components/tei-calculator/testimonial'
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import ContactForm from '@blocks/contact-form';

/**
 * TeiCalculator Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const TeiCalculator = (props) => {
	const {
		pageContext: {
			page: {
				title,
				excerpt,
				uri,
				seo,
				teiPageMeta: { section1, section2, section3 },
			},
			teiCalculatorSettings
		}
	} = props;

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title="TeiCalculator" uri={uri} className="technology-page">
			<>
				<SEO
					title={title}
					seoData={seo}
					uri={uri}
					header={{ siteTitle: 'OpenWeb' }}
					openGraphImage={openGraphImage}
				/>
				<TeiHero title={section1.title} excerpt={excerpt} />
				<TeiForm meta={section2} teiCalculatorSettings={teiCalculatorSettings} />
				{section3 && <TeiTestimonial data={section3} />}
			</>
			<ContactForm />
		</Layout>
	)
};

TeiCalculator.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};


export default TeiCalculator;
